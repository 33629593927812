import { Component,OnInit } from '@angular/core';
declare const $:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'app';

  ngOnInit() {
    $("div").removeClass("container-fluid");
    var global = global || window;
  //  var Buffer = Buffer || [];
    var process = process || {
      env: { DEBUG: undefined },
      version: []
    };  

    $("body").css("background-color", "transparent");
  }

}
